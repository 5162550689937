import React, { useEffect, useState } from "react";
import OTPInput from "../components/ui/OTPinput";
import {
  authTokenAtom,
  authTokenSelector,
  firstPermissionSelector,
} from "../recoil/atoms/userAtom";
import { useRecoilState, useRecoilValue } from "recoil";
import axios from "axios";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router";
import { getPathByKey } from "../helpers/routes";
import useLogout from "../hooks/useLogout";

export default function VerifyAuth() {
  const navigate = useNavigate();
  const logoutUser = useLogout();

  const token = useRecoilValue(authTokenSelector);
  const firstPermission = useRecoilValue(firstPermissionSelector);
  const [authToken, setAuthToken] = useRecoilState(authTokenAtom);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const sendTelegramOtp = async (data) => {
      setIsLoading(true);
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}casino/genotptele`,
          {},
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const { data: res } = response;
          if (res.status == 200) {
            toast.success(res.msg);
          } else {
            toast.error(res.msg);
          }
        })
        .catch((error) => {
          toast.error("Request failed!");
        })
        .finally((e) => {
          setIsLoading(false);
        });
    };
    sendTelegramOtp();
  }, []);

  const onChangeOTP = async (otp) => {
    if (otp.length == 6) {
      setIsLoading(true);
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}casino/verifycodetele`,
          { code: otp },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const { data: res } = response;
          if (res.status == 200) {
            
            toast.success(res.msg);
            setAuthToken(res.data);
            
            let firstAllowedRoute = getPathByKey(firstPermission);
            
            if (firstAllowedRoute) {
              navigate(firstAllowedRoute);
            }
          } else {
            toast.error(res.msg);
          }
        })
        .catch((error) => {
          toast.error("Request failed!");
        })
        .finally((e) => {
          setIsLoading(false);
        });
    }
  };

  return (
    <div
      className="page-wrapper"
      id="main-wrapper"
      data-layout="vertical"
      data-navbarbg="skin6"
      data-sidebartype="full"
      data-sidebar-position="fixed"
      data-header-position="fixed"
    >
      <div className="position-relative overflow-hidden radial-gradient min-vh-100 d-flex align-items-center justify-content-center">
        <div className="d-flex align-items-center justify-content-center w-100">
          <div className="row justify-content-center w-100">
            <div className="col-md-8 col-lg-6 col-xxl-4">
              <div className="card mb-0">
                <div className="card-body">
                  <a className="text-nowrap logo-img text-center d-block py-3 w-100">
                    <h3>Video Panel</h3>
                  </a>
                  <p className="text-center">
                    OTP sent to your registered Telegram
                  </p>

                  <OTPInput
                    autoFocus
                    isNumberInput
                    length={6}
                    className="otpContainer"
                    inputClassName="otpInput"
                    onChangeOTP={onChangeOTP}
                  />

                  <div className="d-block text-center">
                    <button
                      type="button"
                      className="btn btn-outline-secondary"
                      onClick={logoutUser}
                    >
                      Back
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
