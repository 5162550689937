import { matchRoutes, useLocation } from "react-router-dom";
import { routes } from "../router/router";

export const useCurrentPath = () => {
  const routesArray = Object.keys(routes).map(function (key) {
    return { path: routes[key] };
  });
  const location = useLocation();
  const [{ route }] = matchRoutes(routesArray, location);

  return route.path;
};
