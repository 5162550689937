import { toast } from "react-hot-toast";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "@hookform/error-message";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import ConfirmVerify from "./ConfirmVerify";
import DeleteVerify from "./DeleteVerify";
import useLogout from "../../hooks/useLogout";
import useCustomValidation from "../../hooks/useCustomValidation";
import ValidationError from "../ValidationError";
import VideoPlayer from "../uploadVideo/VideoPlayer";

export default function EditVideoDetails({
  resultList,
  video,
  token,
  resetVideoEdit,
  afterVerifyApiCall,
}) {
  const videoOutRef = useRef(null);

  const logoutUser = useLogout();

  const [submitData, setSubmitData] = useState(null);
  const [verifyModal, setVerifyModal] = useState(null);

  const schema = {
    pauseAt: Yup.number()
      .typeError("Pause At must be a number")
      .required("Pause At is required")
      .min(0, `Pause At is minimum 0`)
      .max(video?.dur || 1, `Pause At is maximum ${video?.dur || 1}`),
    result: Yup.string().required("Result is required"),
  };
  const initial = {
    pauseAt: null,
    result: "",
  };
  const { register, errors, handleSubmit, reset, setValue } =
    useCustomValidation({ schema, initial });

  useEffect(() => {
    setValue("pauseAt", video.pause);
    setValue(
      "result",
      JSON.stringify({ rid: video.rid, result: video.result })
    );
    return () => {
      resetVideoEdit();
      setSubmitData(null);
    };
  }, []);

  const [isLoading, setIsLoading] = useState({ status: false, value: null });

  const callSubmitApi = async (type, remark = "") => {
    setIsLoading({ status: true, value: submitData.verify });
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}video/videoverify`,
        { ...submitData, remark: remark },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        const { data: res } = response;
        if (res.status == 200) {
          toast.success(res.msg);
        } else {
          toast.error(res.msg);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response?.status == 401) {
            toast.error("Please Login!");
            logoutUser();
          } else {
            toast.error("Request failed!");
          }
        }
      })
      .finally((e) => {
        setIsLoading({ status: false, value: null });
        afterVerifyApiCall();
      });
  };

  const verifyHandleSubmit = async (data) => {
    const { rid, result } = JSON.parse(data.result);
    let params = {
      dur: video.dur,
      pause: data.pauseAt,
      rid: rid,
      rname: result,
      verify: data.verify,
      oid: video.id,
    };
    setSubmitData(params);
    setVerifyModal(data.verify);
  };

  const closeVerify = () => {
    setVerifyModal(null);
  };

  const getPauseTime = () => {
    if (videoOutRef.current) {
      videoOutRef.current.getPauseTime();
    }
  };

  const setPauseTime = (pauseAt) => {
    setValue("pauseAt", parseInt(pauseAt), { shouldValidate: true });
  };

  return (
    <>
      <div className="modal show d-block">
        <div className="modal-dialog modal-dialog-centered video-verify-modal">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">{video.result}</h4>
              <button
                type="button"
                className="btn-close"
                onClick={resetVideoEdit}
              ></button>
            </div>

            <div className="modal-body">
              <div className="row">
                <div className="col-md-9">
                  {video.path && (
                    <VideoPlayer
                      ref={videoOutRef}
                      src={video.path}
                      setPauseTime={setPauseTime}
                    />
                    // <video
                    //   key={video.path}
                    //   className="w-100 rounded"
                    //   controls
                    //   ref={videoOutRef}
                    // >
                    //   <source src={video.path} type="video/mp4" />
                    // </video>
                  )}
                </div>
                <div className="col-md-3">
                  <div className="mb-3">
                    <label htmlFor="pauseAt" className="form-label">
                      Pause At <small>(ms)</small>
                    </label>
                    <div className="input-group">
                      <input
                        {...register("pauseAt")}
                        type="number"
                        className="form-control"
                        id="pauseAt"
                      />
                      {video.path && (
                        <button
                          className="btn btn-info"
                          type="button"
                          onClick={getPauseTime}
                          title="Click here to get video pause time"
                        >
                          <span>
                            <i className="ti ti-clock-pause"></i>
                          </span>
                        </button>
                      )}
                    </div>
                    <ValidationError name="pauseAt" errors={errors} />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="result" className="form-label">
                      Result
                    </label>
                    <select
                      {...register("result")}
                      className="form-select"
                      aria-label="Default select example"
                      id="result"
                    >
                      <option value="" disabled>
                        Select Result
                      </option>
                      {resultList &&
                        resultList.length &&
                        resultList.map((val, key) => (
                          <option
                            value={JSON.stringify({
                              rid: val.id,
                              result: val.result,
                            })}
                            key={key}
                          >
                            {val.result}
                          </option>
                        ))}
                    </select>
                    <ValidationError name="result" errors={errors} />
                  </div>

                  {(video.verify == 0 || video.verify == 1) && (
                    <>
                      <button
                        type="button"
                        className="btn btn-success form-control mb-3"
                        disabled={isLoading.status}
                        onClick={handleSubmit((data) =>
                          verifyHandleSubmit({
                            ...data,
                            verify: 1,
                          })
                        )}
                      >
                        {(isLoading.status && isLoading.value == 1 && (
                          <div className="spinner-border"></div>
                        )) || <span>Verify</span>}
                      </button>

                      <button
                        type="button"
                        className="btn btn-danger form-control"
                        disabled={isLoading.status}
                        onClick={handleSubmit((data) =>
                          verifyHandleSubmit({
                            ...data,
                            verify: 2,
                          })
                        )}
                      >
                        {(isLoading.status && isLoading.value == 2 && (
                          <div className="spinner-border"></div>
                        )) || <span>Delete</span>}
                      </button>
                    </>
                  )}
                </div>

                {verifyModal == 1 && (
                  <ConfirmVerify
                    isLoading={isLoading}
                    callSubmitApi={callSubmitApi}
                    closeVerify={closeVerify}
                  />
                )}
                {verifyModal == 2 && (
                  <DeleteVerify
                    isLoading={isLoading}
                    callSubmitApi={callSubmitApi}
                    closeVerify={closeVerify}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop show"></div>
    </>
  );
}
