import React, { useState } from "react";
import { toast } from "react-hot-toast";
import * as Yup from "yup";
import { ErrorMessage } from "@hookform/error-message";
import { useRecoilValue } from "recoil";
import { authTokenSelector, userSelector } from "../recoil/atoms/userAtom";
import useTogglePassword from "../hooks/useTogglePassword";
import axios from "axios";
import classNames from "classnames";
import useLogout from "../hooks/useLogout";
import useCustomValidation from "../hooks/useCustomValidation";
import ValidationError from "../components/ValidationError";

export default function ChangePassword() {
  const logoutUser = useLogout();

  const schema = {
    password: Yup.string()
      .required("Existing Password is required")
      .min(3, "Existing Password must be at 3 character long"),
    newPassword: Yup.string()
      .required("New Password is required")
      .min(3, "New Password must be at 3 character long")
      .matches(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])",
        "New Password must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number"
      ),
    newPasswordConfirm: Yup.string()
      .required("New Password Confirmation is required")
      .oneOf(
        [Yup.ref("newPassword")],
        "New Password Confirmation does not match"
      )
      .matches(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])",
        "New Password Confirmation must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number"
      ),
  };
  const initial = {
    password: "",
    newPassword: "",
    newPasswordConfirm: "",
  };
  const { register, errors, handleSubmit, reset, setValue } =
    useCustomValidation({ schema, initial });

  const {
    showPassword: showExistingPassword,
    togglePassword: toggleExistingPassword,
  } = useTogglePassword();
  const { showPassword: showNewPassword, togglePassword: toggleNewPassword } =
    useTogglePassword();
  const {
    showPassword: showNewConfirmPassword,
    togglePassword: toggleNewConfirmPassword,
  } = useTogglePassword();

  const user = useRecoilValue(userSelector);
  const token = useRecoilValue(authTokenSelector);

  const [isLoading, setIsLoading] = useState(false);

  const onUpdatePassword = async (data) => {
    setIsLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}casino/changepass`,
        {
          uname: user.uname,
          utype: "Trade",
          pass: data.password,
          newpass: data.newPassword,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        const { data: res } = response;
        if (res.status == 200) {
          toast.success(res.msg);
          logoutUser();
        } else {
          toast.error(res.msg);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response?.status == 401) {
            toast.error("Please Login!");
            logoutUser();
          } else {
            toast.error("Request failed!");
          }
        }
      })
      .finally((e) => {
        setIsLoading(false);
      });
  };

  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title fw-semibold mb-4">Change Password</h5>
        <div className="card">
          <div className="card-body">
            <form autoComplete="off" onSubmit={handleSubmit(onUpdatePassword)}>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-2">
                    <label htmlFor="existingPassword" className="form-label">
                      Existing Password
                    </label>
                    <div className="input-group">
                      <input
                        {...register("password")}
                        type={showExistingPassword ? "text" : "password"}
                        className="form-control"
                        id="existingPassword"
                      />
                      <button
                        className="btn btn-outline-dark"
                        type="button"
                        onClick={toggleExistingPassword}
                      >
                        <span>
                          <i
                            className={classNames("ti", {
                              "ti-eye": !showExistingPassword,
                              "ti-eye-off": showExistingPassword,
                            })}
                          ></i>
                        </span>
                      </button>
                    </div>
                    <ValidationError name="password" errors={errors} />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="mb-2">
                    <label htmlFor="newPassword" className="form-label">
                      New Password
                    </label>
                    <div className="input-group">
                      <input
                        {...register("newPassword")}
                        type={showNewPassword ? "text" : "password"}
                        className="form-control"
                        id="newPassword"
                      />
                      <button
                        className="btn btn-outline-dark"
                        type="button"
                        onClick={toggleNewPassword}
                      >
                        <span>
                          <i
                            className={classNames("ti", {
                              "ti-eye": !showNewPassword,
                              "ti-eye-off": showNewPassword,
                            })}
                          ></i>
                        </span>
                      </button>
                    </div>
                    <ValidationError name="newPassword" errors={errors} />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="newConfirmPassword" className="form-label">
                      Confirm Password
                    </label>
                    <div className="input-group">
                      <input
                        {...register("newPasswordConfirm")}
                        type={showNewConfirmPassword ? "text" : "password"}
                        className="form-control"
                        id="newConfirmPassword"
                      />
                      <button
                        className="btn btn-outline-dark"
                        type="button"
                        onClick={toggleNewConfirmPassword}
                      >
                        <span>
                          <i
                            className={classNames("ti", {
                              "ti-eye": !showNewConfirmPassword,
                              "ti-eye-off": showNewConfirmPassword,
                            })}
                          ></i>
                        </span>
                      </button>
                    </div>
                    <ValidationError
                      name="newPasswordConfirm"
                      errors={errors}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <button
                    type="submit"
                    disabled={isLoading}
                    className="btn btn-primary w-100 py-8 fs-4 rounded-2"
                  >
                    {(isLoading && <div className="spinner-border"></div>) || (
                      <span>Update Password</span>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
