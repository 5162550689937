import "./assets/css/styles.css"; // Add this line
import "./assets/css/App.css";
import { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { router } from "./router/router";

function App() {
  useEffect(() => {
    import("bootstrap/dist/js/bootstrap.min.js");
  }, []);

  return <RouterProvider router={router} />;
}

export default App;
