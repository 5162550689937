import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { authTokenSelector } from "../../recoil/atoms/userAtom";
import useLogout from "../../hooks/useLogout";
import axios from "axios";
import videoStatusEnums from "../../enums/videoStatusEnums";

export default function CountVideoReport() {
  const token = useRecoilValue(authTokenSelector);
  const logoutUser = useLogout();

  const [countByStatus, setCountByStatus] = useState(null);
  const [countByResults, setCountByResults] = useState(null);

  useEffect(() => {
    const getVideoCount = async () => {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}video/videocount`,
          {},
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const { data: res } = response;
          if (res.status == 200) {
            setCountByStatus(res.data.t1);
            setCountByResults(res.data.t2);
          } else {
            setCountByStatus(null);
            setCountByResults(null);
            toast.error(res.msg);
          }
        })
        .catch((error) => {
          setCountByStatus(null);
          setCountByResults(null);
          if (error.response) {
            if (error.response?.status == 401) {
              toast.error("Please Login!");
              logoutUser();
            } else {
              toast.error("Request failed!");
            }
          }
        });
    };
    getVideoCount();
    return () => {};
  }, []);

  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title fw-semibold mb-4">Video Counts Report</h5>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4 d-flex align-items-stretch">
              <div className="card w-100">
                <div className="card-body p-4">
                  <div className="mb-4">
                    <h5 className="card-title fw-semibold">Counts by Status</h5>
                  </div>
                  <ul className="timeline-widget mb-0 position-relative mb-n5">
                    {(countByStatus &&
                      countByStatus.length &&
                      countByStatus.map((data, key) => (
                        <li
                          key={key}
                          className="timeline-item d-flex position-relative overflow-hidden"
                        >
                          <div className="timeline-time text-dark flex-shrink-0 text-end">
                            {videoStatusEnums[data.status]}
                          </div>
                          <div className="timeline-time text-dark flex-shrink-0 text-end">
                            {data.cnt}
                          </div>
                        </li>
                      ))) || (
                      <li className="timeline-item text-center">
                        No counts available
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-8 d-flex align-items-stretch">
              <div className="card w-100">
                <div className="card-body p-4">
                  <h5 className="card-title fw-semibold mb-4">
                    Counts by Results
                  </h5>
                  <div className="table-responsive">
                    <table className="table text-nowrap mb-0 align-middle">
                      <thead className="text-dark fs-4">
                        <tr>
                          <th className="border-bottom-0">
                            <h6 className="fw-semibold mb-0">Result</h6>
                          </th>
                          <th className="border-bottom-0">
                            <h6 className="fw-semibold mb-0">Total</h6>
                          </th>
                          <th className="border-bottom-0">
                            <h6 className="fw-semibold mb-0">Used</h6>
                          </th>
                          <th className="border-bottom-0">
                            <h6 className="fw-semibold mb-0">Un-Used</h6>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {(countByResults &&
                          countByResults.length &&
                          countByResults.map((data, key) => (
                            <tr key={key}>
                              <td className="border-bottom-0">
                                <p className="mb-0 fw-normal">{data.result}</p>
                              </td>
                              <td className="border-bottom-0">
                                <p className="mb-0 fw-normal">{data.tot}</p>
                              </td>
                              <td className="border-bottom-0">
                                <p className="mb-0 fw-normal">{data.used}</p>
                              </td>
                              <td className="border-bottom-0">
                                <p className="mb-0 fw-normal">{data.unused}</p>
                              </td>
                            </tr>
                          ))) || (
                          <tr>
                            <td colSpan={4}>
                              <p className="text-center">No counts available</p>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
