import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import useScreenSize from "../hooks/useScreenSize";
import { useRecoilValue } from "recoil";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "@hookform/error-message";
import { authTokenSelector } from "../recoil/atoms/userAtom";
import classNames from "classnames";
import axios from "axios";
import useLogout from "../hooks/useLogout";
import useCustomValidation from "../hooks/useCustomValidation";
import ValidationError from "../components/ValidationError";
import VideoPlayer from "../components/uploadVideo/VideoPlayer";

export default function UploadVideo() {
  const videoFileRef = useRef(null);
  const videoOutRef = useRef(null);

  const screenSize = useScreenSize();
  const logoutUser = useLogout();

  const token = useRecoilValue(authTokenSelector);

  const [resultList, setResultList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [videoFile, setVideoFile] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);
  const [videoLoad, setVideoLoad] = useState(false);
  const [videoDuration, setVideoDuration] = useState(null);
  const [videoUploadStatus, setVideoUploadStatus] = useState(false);
  const [videoProgress, setVideoProgress] = useState(0);

  const schema = {
    pauseAt: Yup.number()
      .typeError("Pause At must be a number")
      .required("Pause At is required")
      .min(0, `Pause At is minimum 0`)
      .max(
        videoDuration,
        videoDuration
          ? `Pause At is maximum ${videoDuration}`
          : `Select Video First`
      ),

    result: Yup.string().required("Result is required"),
  };
  const initial = {
    pauseAt: null,
    result: "",
  };
  const { register, errors, handleSubmit, reset, setValue } =
    useCustomValidation({ schema, initial });

  useEffect(() => {
    const getResultList = async () => {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}video/resultlist`,
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((response) => {
          const { data: res } = response;
          if (res.status == 200) {
            setResultList(res.data);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response?.status == 401) {
              toast.error("Please Login!");
              logoutUser();
            } else {
              toast.error("Request failed!");
            }
          }
        });
    };

    getResultList();
  }, []);

  const selectVideoFile = (e) => {
    // Reading New File (open file Picker Box)
    setVideoFile(null);
    setVideoDuration(null);
    setVideoPreview(null);
    // const reader = new FileReader();

    // Gettting Selected File (user can select multiple but we are choosing only one)
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setVideoFile(selectedFile);
      setVideoPreview(URL.createObjectURL(selectedFile));
      setVideoLoad(true);
    }
  };

  const clearFiles = () => {
    setVideoFile(null);
    setVideoPreview(null);
    setVideoDuration(null);
    setVideoUploadStatus(false);
    setVideoProgress(0);
    setVideoLoad(false);
    reset();
    videoFileRef.current.value = null;
  };

  const uploadVideo = async (data) => {
    const { rid, rname } = JSON.parse(data.result);
    if (!videoFile) {
      toast.error("Upload Valid Video File !");
      return;
    }

    setVideoProgress(0);
    setVideoUploadStatus(true);
    setIsLoading(true);

    const formData = new FormData();
    formData.append("dur", videoDuration);
    formData.append("pause", data.pauseAt);
    formData.append("rid", rid);
    formData.append("rname", rname);
    formData.append("vid", videoFile);

    await axios
      .post(`${process.env.REACT_APP_API_URL}video/videoadd`, formData, {
        onUploadProgress: (progressEvent) => {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setVideoProgress(percentCompleted);
        },
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const { data: res } = response;
        if (res.status == 200) {
          toast.success(res.msg);
        } else {
          toast.error(res.msg);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response?.status == 401) {
            toast.error("Please Login!");
            logoutUser();
          } else {
            toast.error("Request failed!");
          }
        }
      })
      .finally((e) => {
        reset();
        setIsLoading(false);
        clearFiles();
      });
  };

  const getPauseTime = () => {
    if (videoOutRef.current) {
      videoOutRef.current.getPauseTime();
    }
  };

  const setPauseTime = (pauseTime) => {
    setValue("pauseAt", parseInt(pauseTime), { shouldValidate: true });
  };

  return (
    <div className="card video-upload">
      <div className="card-body">
        <h5 className="card-title fw-semibold mb-4">Upload Video</h5>
        <div className="card">
          <div className="card-body">
            <form autoComplete="off" onSubmit={handleSubmit(uploadVideo)}>
              <div className="row">
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="videoFile" className="form-label">
                      Video File
                    </label>
                    <div className="file-upload-input">
                      <div className="input-group">
                        <input
                          name="video"
                          type="file"
                          className="form-control"
                          id="videoFile"
                          ref={videoFileRef}
                          accept="video/*"
                          onChange={selectVideoFile}
                        />
                        {videoPreview && (
                          <button
                            className="btn btn-danger"
                            type="button"
                            onClick={clearFiles}
                          >
                            <span>
                              <i className="ti ti-trash"></i>
                            </span>
                          </button>
                        )}
                      </div>
                      {/* <div
                        className={classNames("loader w-100 text-center", {
                          hide: !videoLoad,
                        })}
                      >
                        <div className="spinner-border text-dark" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div> */}
                    </div>

                    {/* {videoDuration && (
                      <div id="fileDesc" className="form-text">
                        {`Video duration is ${parseFloat(videoDuration).toFixed(
                          2
                        )} Seconds`}
                      </div>
                    )} */}
                  </div>
                  {screenSize.width < 768 && videoPreview != null && (
                    <VideoPlayer
                      ref={videoOutRef}
                      src={videoPreview}
                      setDuration={setVideoDuration}
                      setLoading={setIsLoading}
                      setPauseTime={setPauseTime}
                    />
                  )}
                </div>

                <div className="col-md-3">
                  <div className="mb-3">
                    <label htmlFor="pauseAt" className="form-label">
                      Pause At <small>(ms)</small>
                    </label>
                    <div className="input-group">
                      <input
                        {...register("pauseAt", {
                          required: "This input is required.",
                          min: 0,
                          pattern: /^\d*[0-9]\d*$/,
                        })}
                        type="number"
                        className="form-control"
                        id="pauseAt"
                      />
                      {videoPreview && (
                        <button
                          className="btn btn-info"
                          type="button"
                          onClick={getPauseTime}
                          title="Click here to get video pause time"
                        >
                          <span>
                            <i className="ti ti-clock-pause"></i>
                          </span>
                        </button>
                      )}
                    </div>
                    <ValidationError name="pauseAt" errors={errors} />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="mb-3">
                    <label htmlFor="result" className="form-label">
                      Result
                    </label>
                    <select
                      {...register("result", {
                        required: "This input is required.",
                      })}
                      className="form-select"
                      aria-label="Default select example"
                      id="result"
                    >
                      <option value="" disabled>
                        Select Result
                      </option>
                      {resultList &&
                        resultList.length &&
                        resultList.map((val, key) => (
                          <option
                            value={JSON.stringify({
                              rid: val.id,
                              rname: val.result,
                            })}
                            key={key}
                          >
                            {val.result}
                          </option>
                        ))}
                    </select>
                    <ValidationError name="result" errors={errors} />
                  </div>
                </div>

                <div className="col-md-2">
                  <div className="mb-3">
                    <label className="form-label">&nbsp;</label>
                    <button
                      type="submit"
                      className="btn btn-primary form-control"
                      disabled={isLoading}
                    >
                      {(isLoading && (
                        <div className="spinner-border"></div>
                      )) || <span>Submit</span>}
                    </button>

                    {videoUploadStatus && (
                      <div className="progress mt-3">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: `${videoProgress}%` }}
                          aria-valuenow={videoProgress}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          {`${videoProgress}%`}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  {screenSize.width >= 768 && videoPreview != null && (
                    <VideoPlayer
                      ref={videoOutRef}
                      src={videoPreview}
                      setDuration={setVideoDuration}
                      setLoading={setIsLoading}
                      setPauseTime={setPauseTime}
                    />
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
