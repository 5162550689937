import { atom, selector } from "recoil";
import { recoilPersist } from "recoil-persist";
const { persistAtom } = recoilPersist({
  key: "recoil-persist", // this key is using to store data in local storage
  storage: sessionStorage, // configure which storage will be used to store the data
  converter: JSON, // configure how values will be serialized/deserialized in storage
});

// ATOMS
export const userAtom = atom({
  key: "user",
  default: null,
  effects_UNSTABLE: [persistAtom],
});

export const permissionAtom = atom({
  key: "permission",
  default: null,
  effects_UNSTABLE: [persistAtom],
});

export const firstPermissionAtom = atom({
  key: "firstPermission",
  default: null,
  effects_UNSTABLE: [persistAtom],
});

export const authTokenAtom = atom({
  key: "authToken",
  default: null,
  effects_UNSTABLE: [persistAtom],
});

export const barcodeAtom = atom({
  key: "barcode",
  default: null,
  effects_UNSTABLE: [persistAtom],
});

// SELECTORS
export const userSelector = selector({
  key: "userSelector",
  get: ({ get }) => {
    const user = get(userAtom);
    return user;
  },
});
export const permissionSelector = selector({
  key: "permissionSelector",
  get: ({ get }) => {
    const permissino = get(permissionAtom);
    return permissino;
  },
});
export const firstPermissionSelector = selector({
  key: "firstPermissionAtom",
  get: ({ get }) => {
    const permissino = get(firstPermissionAtom);
    return permissino;
  },
});
export const authTokenSelector = selector({
  key: "authTokenSelector",
  get: ({ get }) => {
    const token = get(authTokenAtom);
    return token;
  },
});

export const barcodeSelector = selector({
  key: "barcodeSelector",
  get: ({ get }) => {
    const barcode = get(barcodeAtom);
    return barcode;
  },
});
