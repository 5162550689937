"use client";

import { useRecoilState } from "recoil";
import { Link } from "react-router-dom";
import { userAtom } from "../recoil/atoms/userAtom";
import { sidebarAtom } from "../recoil/atoms/themeAtoms";
import useLogout from "../hooks/useLogout";
import { routes } from "../router/router";

export default function Header() {
  const [user, setUser] = useRecoilState(userAtom);
  const [showSidebar, setShowSidebar] = useRecoilState(sidebarAtom);

  const logoutUser = useLogout();

  return (
    <header className="app-header">
      <nav className="navbar navbar-expand-lg navbar-light">
        <ul className="navbar-nav">
          <li className="nav-item d-block d-xl-none">
            <a
              onClick={() => setShowSidebar(true)}
              className="nav-link sidebartoggler nav-icon-hover"
              id="headerCollapse"
            >
              <i className="ti ti-menu-2"></i>
            </a>
          </li>
          {/* <li className="nav-item">
                        <a className="nav-link nav-icon-hover" >
                            <i className="ti ti-bell-ringing"></i>
                            <div className="notification bg-primary rounded-circle"></div>
                        </a>
                    </li> */}
        </ul>
        <div
          className="navbar-collapse justify-content-end px-0"
          id="navbarNav"
        >
          <ul className="navbar-nav flex-row ms-auto align-items-center justify-content-end">
            <li className="nav-item dropdown">
              <a
                className="nav-link cursor-pointer"
                id="drop2"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  src="../assets/images/profile/user-1.jpg"
                  alt=""
                  width="35"
                  height="35"
                  className="rounded-circle"
                />
                <p className="mb-0 ms-2 text-dark">{user?.uname}</p>
              </a>
              <div
                className="dropdown-menu dropdown-menu-end dropdown-menu-animate-up"
                aria-labelledby="drop2"
              >
                <div className="message-body">
                  <Link
                    to={routes.changePasswordFull}
                    className="d-flex align-items-center gap-2 dropdown-item"
                  >
                    <i className="ti ti-lock fs-6"></i>
                    <p className="mb-0 fs-3">Change Password</p>
                  </Link>
                  <Link
                    to={routes.secureAuthFull}
                    className="d-flex align-items-center gap-2 dropdown-item"
                  >
                    <i className="ti ti-2fa fs-6"></i>
                    <p className="mb-0 fs-3">Secure Auth</p>
                  </Link>
                  <a
                    type="button"
                    onClick={logoutUser}
                    className="btn btn-outline-primary mx-3 mt-2 d-block"
                  >
                    Logout
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
}
