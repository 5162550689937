import { atom, selector } from "recoil";

// ATOMS
export const sidebarAtom = atom({
  key: "showSidebar",
  default: false,
});

// SELECTORS
export const showSidebarSelector = selector({
  key: "showSidebarSelector",
  get: ({ get }) => {
    const user = get(sidebarAtom);
    return user;
  },
});
