import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "@hookform/error-message";
import useCustomValidation from "../../hooks/useCustomValidation";
import ValidationError from "../ValidationError";

export default function DeleteVerify({
  callSubmitApi,
  closeVerify,
  isLoading,
}) {
  const schema = {
    remark: Yup.string().required("Remark is required"),
  };
  const initial = {
    remark: "",
  };
  const { register, errors, handleSubmit, reset, setValue } =
    useCustomValidation({ schema, initial });

  const submitRemark = (data) => {
    callSubmitApi("delete", data.remark);
  };

  return (
    <>
      <div className="modal show d-block">
        <div className="modal-dialog  modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Confirm Delete
              </h5>
            </div>
            <div className="modal-body">
              <p>Are you sure to Delete this video?</p>
              <div className="my-1">
                <input
                  type="text"
                  {...register("remark")}
                  placeholder="Remark for delete"
                  className="form-control"
                />
                <ValidationError name="remark" errors={errors} />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn confirm-button"
                onClick={closeVerify}
                disabled={isLoading.status}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger confirm-button"
                onClick={handleSubmit(submitRemark)}
                disabled={isLoading.status}
              >
                {(isLoading.status && isLoading.value == 1 && (
                  <div className="spinner-border"></div>
                )) || <span>Delete</span>}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop show"></div>
    </>
  );
}
