const routesMap = new Map([
  ["upvideo", { path: "/dashboard/upload_video" }],
  ["verivideo", { path: "/dashboard/verify_video" }],
  ["vidcount", { path: "/dashboard/count_video" }],
]);

export const getPathByKey = (key) => {
  const route = routesMap.get(key);
  return route.path;
};

function getByValue(map, searchValue) {
  for (let [key, value] of map.entries()) {
    if (value.path === searchValue) return key;
  }
}

export const getRoutePermissionKey = (path) => {
  const per = getByValue(routesMap, path);
  return per;
};

export const checkRoutePermission = (permission) => {
  if (routesMap.has(permission?.mencode)) {
    return permission?.isact ? true : false;
  } else {
    return false;
  }
};
