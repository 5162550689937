import { Link } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { checkRoutePermission, getPathByKey } from "../helpers/routes";
import { sidebarAtom } from "../recoil/atoms/themeAtoms";
import {
  firstPermissionAtom,
  permissionSelector,
} from "../recoil/atoms/userAtom";
import { routes } from "../router/router";

export default function LeftSidebar() {
  const [showSidebar, setShowSidebar] = useRecoilState(sidebarAtom);
  const permission = useRecoilValue(permissionSelector);
  const [firstPermission, setFirstPermission] =
    useRecoilState(firstPermissionAtom);
  let firstAllowedRoute = firstPermission
    ? getPathByKey(firstPermission)
    : null;

  return (
    <aside className="left-sidebar">
      <div>
        <div className="brand-logo d-flex align-items-center justify-content-between">
          {firstAllowedRoute && (
            <Link
              to={firstAllowedRoute}
              onClick={() => setShowSidebar(false)}
              className="text-nowrap logo-img"
            >
              <h3>Video Panel</h3>
              {/* <img
              src="../assets/images/logos/dark-logo.svg"
              width="180"
              alt=""
            /> */}
            </Link>
          )}
          <div
            onClick={() => setShowSidebar(false)}
            className="close-btn d-xl-none d-block sidebartoggler cursor-pointer"
            id="sidebarCollapse"
          >
            <i className="ti ti-x fs-8"></i>
          </div>
        </div>

        {permission && (
          <nav className="sidebar-nav scroll-sidebar">
            <ul id="sidebarnav">
              <li className="nav-small-cap">
                <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
                <span className="hide-menu">1 Ball Cricket</span>
              </li>

              {checkRoutePermission(permission["upvideo"]) && (
                <li className="sidebar-item">
                  <Link
                    to={routes.uploadVideoFull}
                    onClick={() => setShowSidebar(false)}
                    className="sidebar-link"
                    aria-expanded="false"
                  >
                    <span>
                      <i className="ti ti-cloud-upload"></i>
                    </span>
                    <span className="hide-menu">Upload Video</span>
                  </Link>
                </li>
              )}
              {checkRoutePermission(permission["verivideo"]) && (
                <li className="sidebar-item">
                  <Link
                    to={routes.verifyVideoFull}
                    onClick={() => setShowSidebar(false)}
                    className="sidebar-link"
                    aria-expanded="false"
                  >
                    <span>
                      <i className="ti ti-zoom-check"></i>
                    </span>
                    <span className="hide-menu">Verify Video</span>
                  </Link>
                </li>
              )}
              {checkRoutePermission(permission["vidcount"]) && (
                <li className="sidebar-item">
                  <Link
                    to={routes.countVideoReportFull}
                    onClick={() => setShowSidebar(false)}
                    className="sidebar-link"
                    aria-expanded="false"
                  >
                    <span>
                      <i className="ti ti-calculator"></i>
                    </span>
                    <span className="hide-menu">Video Counts Report</span>
                  </Link>
                </li>
              )}
            </ul>
          </nav>
        )}
      </div>
    </aside>
  );
}
