import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

const VideoPlayer = forwardRef(
  (
    { src, setDuration = () => {}, setLoading = () => {}, setPauseTime },
    ref
  ) => {
    const videoRef = useRef();

    const [currentTime, setCurrentTime] = useState(0);
    const [totalTime, setTotalTime] = useState(null);
    const [isPlay, setIsPlay] = useState(false);
    const [isMute, setIsMute] = useState(false);

    useImperativeHandle(ref, () => ({
      getPauseTime() {
        console.log("here", currentTime);
        setPauseTime(currentTime);
      },
    }));

    useEffect(() => {
      setDuration(totalTime);
      return () => {};
    }, [totalTime]);

    useEffect(() => {
      setCurrentTime(parseInt(videoRef.current.currentTime * 1000));
      return () => {};
    }, [videoRef]);

    const togglePlayVideo = () => {
      const video = videoRef.current;
      if (video.paused) {
        video.play();
        setIsPlay(true);
      } else {
        video.pause();
        setIsPlay(false);
      }
    };

    const toggleMuteVideo = () => {
      const video = videoRef.current;
      if (video.muted) {
        video.muted = false;
        setIsMute(false);
      } else {
        video.muted = true;
        setIsMute(true);
      }
    };

    //   const fullScreen = () => {
    //     const video = videoRef.current;
    //     if (video.requestFullscreen) {
    //       video.requestFullscreen();
    //     } else if (video.mozRequestFullScreen) {
    //       video.mozRequestFullScreen();
    //     } else if (video.webkitRequestFullscreen) {
    //       video.webkitRequestFullscreen();
    //     } else if (video.msRequestFullscreen) {
    //       video.msRequestFullscreen();
    //     }
    //   };

    const seekVideo = (e) => {
      let seekVideoTo = e.target.value;
      const video = videoRef.current;
      video.currentTime = seekVideoTo / 1000;
    };

    return (
      <div className="border rounded">
        <video
          className="w-100 cursor-pointer rounded-top"
          ref={videoRef}
          src={src}
          onClick={togglePlayVideo}
          onLoadedMetadata={(e) => {
            setTotalTime(parseInt(e.target.duration * 1000));
          }}
          onCanPlay={() => {
            setLoading(false);
          }}
          onTimeUpdate={(e) => {
            setCurrentTime(parseInt(e.target.currentTime * 1000));
          }}
        ></video>

        <div className="px-4 py-2">
          <div className="d-flex justify-content-between">
            <div>
              <button
                type="button"
                className="btn border border-0"
                onClick={togglePlayVideo}
              >
                {isPlay ? (
                  <i className="ti ti-player-pause fs-7"></i>
                ) : (
                  <i className="ti ti-player-play fs-7"></i>
                )}
              </button>
              <span className="mx-2">
                <b>{currentTime}</b> / <b>{totalTime}</b> <small>(ms)</small>
              </span>
            </div>
            <div>
              <button
                type="button"
                className="btn border border-0"
                onClick={toggleMuteVideo}
              >
                {isMute ? (
                  <i className="ti ti-volume-off fs-7"></i>
                ) : (
                  <i className="ti ti-volume fs-7"></i>
                )}
              </button>
              {/* <button
              type="button"
              className="btn border border-0"
              onClick={fullScreen}
            >
              <i className="ti ti-maximize fs-7"></i>
            </button> */}
            </div>
          </div>
          <div>
            <input
              onChange={seekVideo}
              onInput={seekVideo}
              type="range"
              className="form-range"
              min="0"
              max={totalTime}
              step={1}
              value={currentTime}
            />
          </div>
        </div>
      </div>
    );
  }
);
export default VideoPlayer;
