import React, { useEffect, useRef, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import LeftSidebar from "../components/LeftSidebar";
import Header from "../components/Header";
import classNames from "classnames";
import { useCurrentPath } from "../hooks/useCurrentPath";
import { useRecoilValue } from "recoil";
import {
  authTokenSelector,
  barcodeSelector,
  permissionSelector,
} from "../recoil/atoms/userAtom";
import { showSidebarSelector } from "../recoil/atoms/themeAtoms";
import { checkRoutePermission, getRoutePermissionKey } from "../helpers/routes";
import axios from "axios";
import useLogout from "../hooks/useLogout";
import { toast } from "react-hot-toast";
import useScreenSize from "../hooks/useScreenSize";
import { routes } from "../router/router";

export default function Dashboard() {
  const navigate = useNavigate();
  const pathname = useCurrentPath();

  const barcodeInterval = useRef();

  const logoutUser = useLogout();
  const screenSize = useScreenSize();

  const barcode = useRecoilValue(barcodeSelector);
  const permission = useRecoilValue(permissionSelector);

  const token = useRecoilValue(authTokenSelector);
  const showSidebar = useRecoilValue(showSidebarSelector);

  //   useEffect(() => {
  //     if (!token) {
  //       navigate(routes.login);
  //     }
  //   }, [token]);

  useEffect(() => {
    const permissionKey = getRoutePermissionKey(pathname);
    if (permissionKey && permission) {
      // console.log(permissionKey);
      const hasPermission = checkRoutePermission(
        permission[permissionKey] || { dontCheck: true, key: permissionKey }
      );
      // console.log(pathname, hasPermission);
      if (!hasPermission) {
        return navigate(routes.restricted);
      }
    }
  }, [pathname]);

  useEffect(() => {
    const getBarcode = async () => {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}casino/barc`,
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((response) => {
          const { data: res } = response;
          if (res.status == 200) {
            if (res.data.bcode != barcode) {
              clearTimeout(barcodeInterval.current);
              logoutUser();
            } else {
              barcodeInterval.current = setTimeout(() => {
                getBarcode();
              }, 60000);
            }
          } else {
            barcodeInterval.current = setTimeout(() => {
              getBarcode();
            }, 60000);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response?.status == 401) {
              clearTimeout(barcodeInterval.current);
              toast.error("Please Login!");
              logoutUser();
            } else {
              barcodeInterval.current = setTimeout(() => {
                getBarcode();
              }, 60000);
            }
          } else {
            barcodeInterval.current = setTimeout(() => {
              getBarcode();
            }, 60000);
          }
        });
    };

    getBarcode();

    return () => {
      clearTimeout(barcodeInterval.current);
    };
  }, []);

  return (
    <div
      className={classNames("page-wrapper", { "show-sidebar": showSidebar })}
      id="main-wrapper"
      data-layout="vertical"
      data-navbarbg="skin6"
      data-sidebartype={screenSize.width > 1200 ? "full" : "mini-sidebar"}
      data-sidebar-position="fixed"
      data-header-position="fixed"
    >
      <LeftSidebar />
      <div className="body-wrapper">
        <Header />
        <div className="container-fluid">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
