import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  authTokenAtom,
  barcodeAtom,
  firstPermissionAtom,
  permissionAtom,
  userAtom,
} from "../recoil/atoms/userAtom";
import { sidebarAtom } from "../recoil/atoms/themeAtoms";
import { routes } from "../router/router";

const useLogout = () => {
  const navigate = useNavigate();
  const [user, setUser] = useRecoilState(userAtom);
  const [authToken, setAuthToken] = useRecoilState(authTokenAtom);
  const [barcode, setBarcode] = useRecoilState(barcodeAtom);
  const [permission, setPermission] = useRecoilState(permissionAtom);
  const [firstPermission, setFirstPermission] =
    useRecoilState(firstPermissionAtom);

  return () => {
    setAuthToken(null);
    setUser(null);
    setBarcode(null);
    setPermission(null);
    setFirstPermission(null);
    navigate(routes.login);
  };
};

export default useLogout;
