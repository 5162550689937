import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import useCustomValidation from "../hooks/useCustomValidation";
import ValidationError from "../components/ValidationError";
import useTogglePassword from "../hooks/useTogglePassword";
import classNames from "classnames";
import { async } from "q";
import axios from "axios";
import { authTokenSelector } from "../recoil/atoms/userAtom";
import { useRecoilValue } from "recoil";
import useLogout from "../hooks/useLogout";
import OTPInput from "../components/ui/OTPinput";

export default function SecureAuth() {
  const token = useRecoilValue(authTokenSelector);

  const logoutUser = useLogout();

  const schema = {
    password: Yup.string()
      .required("Password is required")
      .min(3, "Password must be at 3 character long"),
  };
  const initial = {
    password: "",
  };
  const { register, errors, handleSubmit, reset, setValue } =
    useCustomValidation({ schema, initial });

  const { showPassword, togglePassword } = useTogglePassword();

  const [authId, setAuthId] = useState(null);
  const [botName, setBotName] = useState("securepanel_bot");
  const [connectionId, setConnectionId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [authOffStatus, setAuthOffStatus] = useState(false);

  useEffect(() => {
    const getAuthStatus = async () => {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}casino/getauth`,
          {},
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const { data: res } = response;
          if (res.status == 200) {
            setAuthId(parseInt(res.data));
          } else {
            setAuthId(0);
          }
        })
        .catch((error) => {
          setAuthId(null);
          if (error.response) {
            if (error.response?.status == 401) {
              toast.error("Please Login!");
              logoutUser();
            } else {
              toast.error("Request failed!");
            }
          }
        });
    };

    getAuthStatus();
  }, []);

  const getConnectionId = async (data) => {
    setIsLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}casino/authontele`,
        {
          upass: data.password,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        const { data: res } = response;
        if (res.status == 200) {
          setConnectionId(res.data);
          toast.success(res.msg);
        } else {
          setConnectionId(null);
          toast.error(res.msg);
        }
      })
      .catch((error) => {
        setConnectionId(null);
        if (error.response) {
          if (error.response?.status == 401) {
            toast.error("Please Login!");
            logoutUser();
          } else {
            toast.error("Request failed!");
          }
        }
      })
      .finally((e) => {
        setIsLoading(false);
      });
  };

  const disableSendCode = async () => {
    setIsLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}casino/userchecktele`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        const { data: res } = response;
        if (res.status == 200) {
          toast.success(res.msg);
          setAuthOffStatus(true);
        } else {
          toast.error(res.msg);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response?.status == 401) {
            toast.error("Please Login!");
            logoutUser();
          } else {
            toast.error("Request failed!");
          }
        }
      })
      .finally((e) => {
        setIsLoading(false);
      });
  };

  const onChangeOTP = async (otp) => {
    if (otp.length == 6) {
      setIsLoading(true);
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}casino/authofftele`,
          { code: otp },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const { data: res } = response;
          if (res.status == 200) {
            toast.success(res.msg);
            logoutUser();
          } else {
            toast.error(res.msg);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response?.status == 401) {
              toast.error("Please Login!");
              logoutUser();
            } else {
              toast.error("Request failed!");
            }
          }
        })
        .finally((e) => {
          setIsLoading(false);
        });
    }
  };

  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title fw-semibold mb-4">Secure Authentication</h5>
        {authId != null && (
          <>
            <div className="text-center my-5">
              <h6>
                Secure Authentication Status
                {((authId == 1 || authId == 2) && (
                  <button
                    className="btn btn-sm btn-success ms-2"
                    data-bs-toggle="tooltip"
                    title="Click here to Disable Secure Authentication"
                    onClick={disableSendCode}
                  >
                    Enabled
                  </button>
                )) || (
                  <button className="btn btn-sm btn-danger ms-2" disabled>
                    Disabled
                  </button>
                )}
              </h6>
              <p>
                Please follow below instructions to Enable Telegran Secure
                Authentication
              </p>
            </div>
            {(authOffStatus && (
              <OTPInput
                autoFocus
                isNumberInput
                length={6}
                className="otpContainer"
                inputClassName="otpInput"
                onChangeOTP={onChangeOTP}
              />
            )) ||
              (authId == 0 && (
                <div className="text-center">
                  <div className="my-5">
                    <h6>
                      1. Enter your login password to get Telegran Connection Id
                    </h6>

                    <div className="row justify-content-center my-3">
                      <div className="col-md-4">
                        <div className="input-group mb-3 mb-lg-0">
                          <input
                            {...register("password")}
                            type={showPassword ? "text" : "password"}
                            className="form-control py-8"
                            id="existingPassword"
                          />
                          <button
                            className="btn btn-outline-dark"
                            type="button"
                            onClick={togglePassword}
                          >
                            <span>
                              <i
                                className={classNames("ti", {
                                  "ti-eye": !showPassword,
                                  "ti-eye-off": showPassword,
                                })}
                              ></i>
                            </span>
                          </button>
                        </div>
                        <ValidationError name="password" errors={errors} />
                      </div>
                      <div className="col-md-3">
                        <button
                          type="button"
                          onClick={handleSubmit(getConnectionId)}
                          disabled={isLoading}
                          className="btn btn-primary w-100 py-8 rounded-2"
                        >
                          {(isLoading && (
                            <div className="spinner-border"></div>
                          )) || <span>Get Connection Id</span>}
                        </button>
                      </div>
                    </div>
                  </div>
                  {connectionId && (
                    <div className="my-5">
                      <h6>2. Your Telegran Connection Id is here</h6>
                      <div className="my-3">
                        <p>
                          Find
                          <a
                            target="_blank"
                            href={`https://t.me/${botName}?start`}
                            class="text-primary"
                          >
                            <span className="mx-1">@{botName}</span>
                          </a>
                          in your telegram and type
                          <kbd className="mx-1">/start</kbd>
                          command. Bot will respond you.
                        </p>
                        <p>
                          After this type{" "}
                          <kbd className="mx-1">/connect {connectionId}</kbd>{" "}
                          and send it to BOT.
                        </p>
                        <p>
                          Now your telegram account will be linked with your
                          website account and 2-Step veriication will be
                          enabled.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              ))}
          </>
        )}
      </div>
    </div>
  );
}
