import React from "react";

export default function Restricted() {
  return (
    <div className="row d-flex justify-content-center">
      <div className="col-md-7">
        <img
          src="../assets/images/restricted.jpg"
          alt=""
          className="img-fluid"
        />
        <h5 className="text-center">You are not allowed to access this page!</h5>
      </div>
    </div>
  );
}
