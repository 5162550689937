import React from "react";

export default function ConfirmVerify({
  callSubmitApi,
  closeVerify,
  isLoading,
}) {
  return (
    <>
      <div className="modal show d-block">
        <div className="modal-dialog  modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Confirm Verify
              </h5>
            </div>
            <div className="modal-body">
              <p>Are you sure to verify this video?</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn confirm-button"
                onClick={closeVerify}
                disabled={isLoading.status}
              >
                Decline
              </button>
              <button
                type="button"
                className="btn btn-success confirm-button"
                onClick={() => callSubmitApi("verify")}
                disabled={isLoading.status}
              >
                {(isLoading.status && isLoading.value == 1 && (
                  <div className="spinner-border"></div>
                )) || <span>Verify</span>}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop show"></div>
    </>
  );
}
