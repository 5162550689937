import { createBrowserRouter } from "react-router-dom";
import Login from "../pages/Login";
import Dashboard from "../lauouts/Dashboard";
import UploadVideo from "../pages/UploadVideo";
import VerifyVideo from "../pages/VerifyVideo";
import ChangePassword from "../pages/ChangePassword";
import SecureAuth from "../pages/SecureAuth";
import ProtectedRoute from "./ProtectedRoute";
import VerifyAuth from "../pages/VerifyAuth";
import Restricted from "../pages/Restricted";
import CountVideoReport from "../pages/reports/CountVideoReport";

export const routes = {
  login: "/",
  verifyAuth: "/verify_auth",
  dashboard: "dashboard",
  uploadVideo: "upload_video",
  uploadVideoFull: "/dashboard/upload_video",
  verifyVideo: "verify_video",
  verifyVideoFull: "/dashboard/verify_video",
  countVideoReport: "count_video",
  countVideoReportFull: "/dashboard/count_video",
  changePassword: "change_password",
  changePasswordFull: "/dashboard/change_password",
  secureAuth: "secure_auth",
  secureAuthFull: "/dashboard/secure_auth",
  restricted: "/dashboard/restricted",
};

export const router = createBrowserRouter([
  {
    path: routes.login,
    element: <Login />,
  },
  {
    path: routes.verifyAuth,
    element: <VerifyAuth />,
  },
  {
    path: "/",
    element: <ProtectedRoute />,
    children: [
      {
        path: routes.dashboard,
        element: <Dashboard />,
        children: [
          {
            path: routes.uploadVideo,
            element: <UploadVideo />,
          },
          {
            path: routes.verifyVideo,
            element: <VerifyVideo />,
          },
          {
            path: routes.countVideoReport,
            element: <CountVideoReport />,
          },
          {
            path: routes.changePassword,
            element: <ChangePassword />,
          },
          {
            path: routes.secureAuth,
            element: <SecureAuth />,
          },
          {
            path: routes.restricted,
            element: <Restricted />,
          },
        ],
      },
    ],
  },
]);
