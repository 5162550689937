"use client";

import { useState, useEffect } from "react";

const checkBreakpointnDevice = (width) => {
  if (width >= 1400) {
    return "xxl";
  } else if (width >= 1200) {
    return "xl";
  } else if (width >= 992) {
    return "lg";
  } else if (width >= 768) {
    return "md";
  } else if (width >= 576) {
    return "sm";
  } else {
    return "esm";
  }
};

const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
    deviceSize: checkBreakpointnDevice(window.innerWidth),
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
        deviceSize: checkBreakpointnDevice(window.innerWidth),
      });
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return screenSize;
};

export default useScreenSize;
